import './style.scss';

// HEADER
(function() {

  var headerMenuToggle = document.querySelectorAll('.hamburger');
  if (headerMenuToggle.length > 0) {
    for (var i = 0; i < headerMenuToggle.length; i++) {
      headerMenuToggle[i].addEventListener('click', toggleMenu);
    }
  }

  function toggleMenu(e) {
    e.preventDefault();
    var menuToggle = e.target.closest('.hamburger');
    menuToggle.classList.toggle('is-active');
    menuToggle.previousElementSibling.classList.toggle('is-active');
  }

})()

if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest =
  function(s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
        i,
        el = this;
    do {
      i = matches.length;
      while (--i >= 0 && matches.item(i) !== el) {};
    } while ((i < 0) && (el = el.parentElement));
    return el;
  };
}